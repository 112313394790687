'use client';

import { useDynamicConfig } from '@statsig/react-bindings';
import noop from 'lodash/noop';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

import {
  type CartSettingsJson,
  useCartSettings,
} from '@/queries/GetCartSettings';
import { useRefreshCart } from '@/queries/RefreshCart';
import { useSavePromotion } from '@/queries/SavePromotion';
import type {
  RefreshCart_cart_refreshCart,
  RefreshCart_cart_refreshCart_cart_lineItems,
} from '@/queries/types/RefreshCart';
import type { SavePromotion_cart_savePromotion } from '@/queries/types/SavePromotion';

export interface CartContextValues {
  cartSettings: CartSettingsJson | null | undefined;
  cartSettingsError: Error | null | undefined;
  cartSettingsLoading: boolean;
  cartSettingsSuccess: boolean;
  handleSetUrlDiscountCode: (code: string) => void;
  maxAddOnQuantityReachedByBaseSku: Record<string, boolean>;
  maxChargerQuantityReached: boolean;
  maxRingQuantityReached: boolean;
  refreshCart: RefreshCart_cart_refreshCart | null | undefined;
  refreshCartError: Error | null | undefined;
  refreshCartLoading: boolean;
  refreshCartSuccess: boolean;
  sitewideDiscount: SavePromotion_cart_savePromotion | null | undefined;
  sitewideDiscountError: Error | null | undefined;
  sitewideDiscountLoading: boolean;
  sitewideDiscountSuccess: boolean;
  urlDiscount: SavePromotion_cart_savePromotion | null | undefined;
  urlDiscountError: Error | null | undefined;
  urlDiscountLoading: boolean;
  urlDiscountSuccess: boolean;
  urlDiscountCode: string;
}

const DEFAULT_CONTEXT_VALUES: CartContextValues = {
  cartSettings: null,
  cartSettingsError: null,
  cartSettingsLoading: true,
  cartSettingsSuccess: false,
  handleSetUrlDiscountCode: noop,
  maxAddOnQuantityReachedByBaseSku: {},
  maxChargerQuantityReached: false,
  maxRingQuantityReached: false,
  refreshCart: null,
  refreshCartError: null,
  refreshCartLoading: true,
  refreshCartSuccess: false,
  sitewideDiscount: null,
  sitewideDiscountError: null,
  sitewideDiscountLoading: true,
  sitewideDiscountSuccess: false,
  urlDiscount: null,
  urlDiscountError: null,
  urlDiscountLoading: true,
  urlDiscountSuccess: false,
  urlDiscountCode: '',
};

export const CartContext = createContext<CartContextValues>(
  DEFAULT_CONTEXT_VALUES,
);

const CartProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const cartSettings = useCartSettings();

  const { value: sitewideDiscountConfig } =
    useDynamicConfig('sitewide_discount');
  const sitewideDiscountCode = sitewideDiscountConfig['sitewideDiscount'] as
    | string
    | null;
  const sitewideDiscount = useSavePromotion(sitewideDiscountCode ?? '');

  const [urlDiscountCode, setUrlDiscountCode] = useState<string>('');
  const urlDiscount = useSavePromotion(urlDiscountCode);
  const fetchingDiscounts = sitewideDiscount.isLoading || urlDiscount.isLoading;

  const refreshCart = useRefreshCart(fetchingDiscounts);

  const maxRingQuantityReached = useMemo(() => {
    const cart = refreshCart.data?.cart;
    const maxRingQuantity = cart?.maxRingQuantity;
    const ringCount = cart?.ringCount;
    if (typeof maxRingQuantity === 'number' && typeof ringCount === 'number') {
      return maxRingQuantity === ringCount;
    } else {
      return false;
    }
  }, [refreshCart]);

  const maxChargerQuantityReached = useMemo(() => {
    const cart = refreshCart.data?.cart;
    const maxChargerQuantity = cart?.maxChargerQuantity;
    const chargerCount = cart?.chargerCount;
    if (
      typeof maxChargerQuantity === 'number' &&
      typeof chargerCount === 'number'
    ) {
      return maxChargerQuantity === chargerCount;
    } else {
      return false;
    }
  }, [refreshCart]);

  const maxAddOnQuantityReachedByBaseSku = useMemo((): Record<
    string,
    boolean
  > => {
    const maxAddOnQuantityByBaseSku =
      cartSettings.data?.maxAddOnQuantityByBaseSku ?? {};
    const lineItems =
      refreshCart.data?.cart?.lineItems?.filter(
        (lineItem): lineItem is RefreshCart_cart_refreshCart_cart_lineItems =>
          Boolean(lineItem),
      ) ?? [];
    return lineItems.reduce((acc, cur) => {
      const skuCode = cur.skuCode;
      if (skuCode) {
        const baseSkus = Object.keys(maxAddOnQuantityByBaseSku);
        const baseSkuForLineItem =
          baseSkus.find((baseSku) => skuCode.startsWith(baseSku)) ?? '';
        const totalQuantityForLineItem = lineItems.reduce(
          (totalQuantity, lineItem) =>
            skuCode === lineItem.skuCode
              ? totalQuantity + (lineItem.quantity ?? 0)
              : totalQuantity,
          0,
        );
        const maxQuantityForLineItem =
          maxAddOnQuantityByBaseSku[baseSkuForLineItem];
        return {
          ...acc,
          [baseSkuForLineItem]:
            maxQuantityForLineItem === totalQuantityForLineItem,
        };
      }
      return acc;
    }, {});
  }, [cartSettings, refreshCart]);

  const onSetUrlDiscountCode = (code: string) => {
    setUrlDiscountCode(code);
  };

  const value: CartContextValues = useMemo(
    () => ({
      cartSettings: cartSettings.data,
      cartSettingsError: cartSettings.error,
      cartSettingsLoading: cartSettings.isLoading,
      cartSettingsSuccess: cartSettings.isSuccess,
      handleSetUrlDiscountCode: onSetUrlDiscountCode,
      maxAddOnQuantityReachedByBaseSku,
      maxChargerQuantityReached,
      maxRingQuantityReached,
      refreshCart: refreshCart.data,
      refreshCartError: refreshCart.error,
      refreshCartLoading: refreshCart.isLoading,
      refreshCartSuccess: refreshCart.isSuccess,
      sitewideDiscount: sitewideDiscount.data,
      sitewideDiscountError: sitewideDiscount.error,
      sitewideDiscountLoading: sitewideDiscount.isLoading,
      sitewideDiscountSuccess: sitewideDiscount.isSuccess,
      urlDiscount: urlDiscount.data,
      urlDiscountError: urlDiscount.error,
      urlDiscountLoading: urlDiscount.isLoading,
      urlDiscountSuccess: urlDiscount.isSuccess,
      urlDiscountCode,
    }),
    [
      cartSettings,
      maxAddOnQuantityReachedByBaseSku,
      maxChargerQuantityReached,
      maxRingQuantityReached,
      refreshCart,
      sitewideDiscount,
      urlDiscount,
      urlDiscountCode,
    ],
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export const useCartContext = () => useContext(CartContext);

export default CartProvider;
