import { useQuery, type UseQueryResult } from '@tanstack/react-query';
import { gql, request } from 'graphql-request';

import { getGraphQlEndpoint } from '../api/backendAPI';
import type { GetCartSettings } from './types/GetCartSettings';

const query = gql`
  query GetCartSettings {
    content {
      cartSettings: getSettings(settingName: "cart") {
        contentsJson
      }
    }
  }
`;

export interface CartSettingsJson {
  documentKey: 'cart';
  documentType: 'settings';
  itemKey: 'document/settings/cart';
  itemType: 'document';
  maxAddOnQuantityByBaseSku: Record<string, number>;
  maxChargerQuantity: number;
  maxRingQuantity: number;
  settingsKey: 'cart';
}

export function withQueryKey() {
  return {
    queryKey: ['GetCartSettings'],
    queryFn: async () => {
      const url = getGraphQlEndpoint()!;
      const data = await request<GetCartSettings | undefined>(url, query);
      return (
        data?.content?.cartSettings?.contentsJson
          ? JSON.parse(data.content.cartSettings.contentsJson)
          : {}
      ) as CartSettingsJson;
    },
  };
}

export function useCartSettings(): UseQueryResult<CartSettingsJson, Error> {
  return useQuery(withQueryKey());
}
