export const MESSAGE_SUCCESS_DEFAULT = 'pdp_discount_applied';
export const MESSAGE_HIGHEST_DISCOUNT =
  'sitewide_highest_discount_eyebrow_copy';
export const ERROR_EXPIRED = 'expired';
export const ERROR_NO_USES_REMAIN = 'no_uses_remaining';
export const ERROR_NOT_FOUND = 'discount_not_found';
export const MESSAGE_ERROR_USED = 'pdp_discount_error_used';
export const MESSAGE_ERROR_EXPIRED = 'pdp_discount_error_expired';
export const MESSAGE_ERROR_DEFAULT = 'pdp_discount_error';
export const DISCOUNT_CODE_NOT_FOUND = 'DiscountCodeNotFound';
export const DISCOUNT_CODE_INVALID = 'DiscountCodeInvalid';
export const PROMOTION_CODE = 'promotion_code';
